import { ENDropdown } from "en-react/dist/src/components/Dropdown"
import { ENDropdown as ENDropdownType } from "en-web-components/dist/en/components/dropdown/dropdown"
import { useEffect, useRef, useState } from "react"
import { DROPDOWN_PAGE_SIZE } from "src/utils/constants"
import { ENLazyDropdownType } from "../FormComponents.types"

const ENPaginatedSingleSelect: React.FC<ENLazyDropdownType> = ({
  id,
  value = undefined,
  error,
  onChange,
  placeholder = "Select",
  disabled = false,
  isClearable = false,
  loadOptions,
  fieldNote,
  label,
  enableServerSideSearch = false,
  enableClientSideSearch = false,
  lazyLoadingScrollOffset = DROPDOWN_PAGE_SIZE,
  debounceIntervalInMilliSecond = 800,
  returnObject = false,
}): JSX.Element => {
  const dropdownRef = useRef<ENDropdownType>(null)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const setInitialDataSource = async () => {
    const response = await loadOptions(1, "")
    dropdownRef.current?.firstUpdated()
    setDataSource(response)
    setDataLoaded(true)
  }

  useEffect(() => {
    if (!disabled) {
      setInitialDataSource()
    }
  }, [disabled])

  return (
    <ENDropdown
      key={!dataLoaded ? 0 : 1}
      data-testid={id}
      ref={dropdownRef}
      label={label}
      placeholder={placeholder}
      value={returnObject && value ? value.value?.toString() : value?.value?.toString() || value}
      onSelectValue={(selectedValue: any) => {
        const detail = selectedValue.detail
        if (returnObject) {
          onChange({
            label: detail.selectedLabel,
            value: detail.selectedValue,
          })
        } else {
          onChange(detail.selectedValue)
        }
      }}
      enableLazyLoading
      lazyLoadingScrollOffset={lazyLoadingScrollOffset}
      showLoaderOnLazyLoading
      lazyLoadingService={loadOptions}
      dataSource={disabled ? (value ? [value] : []) : dataSource}
      isDisabled={!dataSource?.length || disabled}
      fieldNote={fieldNote}
      errorNote={error}
      enableServerSideSearch={enableServerSideSearch}
      hasSearch={enableClientSideSearch || enableServerSideSearch}
      debounceIntervalInMilliSecond={debounceIntervalInMilliSecond}
      enableClearSelection={isClearable}
    />
  )
}

export default ENPaginatedSingleSelect
